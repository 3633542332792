/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby';
import withRoleRequired from '../../../../lib/withRoleRequired';
import Layout from '../../../../components/layout'
import SEO from '../../../../components/seo'
import { Scenario, Persona, PersonaQueryData } from '../../../../types/Global';
import IndexCard from '../../../../components/IndexCard';
import { mapEdgesToNodes, buildPersona } from '../../../../lib/helpers';

interface Props {
  data: {
    allPersonasCsv: PersonaQueryData
    scenariosCsv: Scenario
  },
  params: {
    scenario: string;
  }  
}

const ScenarioPersonaIndexPageTemplate: React.FunctionComponent<Props> = ({ data }) => {
  if (!data.scenariosCsv) return null;
  const personas: Persona[] = mapEdgesToNodes(data.allPersonasCsv) || [];
  const { title, slug } = data.scenariosCsv;
  const thisScenarioPersonas = personas.filter((persona) => persona.scenario === slug);
  return (
    <Layout
      pageHeader="Scenario Personas"
      breadcrumbs={[{ label: 'Scenarios', link: '/scenarios' }, { label: title, link: `/scenarios/${slug}` }]}
      moreInfo="scenarios/personas"
    >
      <SEO title={`${title} Personas`} />
      <div className="max-w-6xl px-4 py-4 mx-auto md:px-8 md:py-4">
        <div className="mb-8">
          This page enables you to select from a set of personas. These contain the details of individuals who may be of interest to Defence within this scenario. The personas represent fictional characters that are designed to capture background information as well as viewpoints and experiences. These personas are informed by various research outputs from the project, including focus groups, social media analysis, SWOT analyses conducted with Defence stakeholders and a survey of attitudes towards Defence.
        </div>
        <section>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {thisScenarioPersonas && thisScenarioPersonas.map((persona) => {
              const filteredPersonas = personas.filter((filteredPersona) => filteredPersona.persona === persona.persona);
              const thisPersona = buildPersona(filteredPersonas, slug);
              if (!thisPersona) return null;
              return (
                <IndexCard
                  key={thisPersona.persona}
                  link={`/scenarios/${slug}/personas/${thisPersona.persona}`}
                  state={{ scenarioName: title, scenarioSlug: slug }}
                  imageSource={thisPersona.profileImage || ""}
                  title={thisPersona.name || ""}
                  description={thisPersona.intro}
                />
              )
            })}
          </div>
        </section>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
query($scenario: String) {
  scenariosCsv(slug: { eq: $scenario }) {
    title
    slug
  }  
  allPersonasCsv {
    edges {
      node {
        persona
        scenario
        name
        intro
        profileImage
      }
    }
  }
}
`

export default withRoleRequired(ScenarioPersonaIndexPageTemplate);
